<template>
  <div class="page-home">
    <img src="/assets/logo.svg" class="b2p-logo" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
};
</script>

<style>
.page-home {
  height: 100vh;
  display: flex;

  align-items: center;
  justify-content: center;
}
.b2p-logo {
  width: 20em;
}
</style>
